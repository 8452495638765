import React, { useState, useEffect, useContext, useCallback } from "react";
import PageTitle from "../../Components/PageTitle";
import { SubmitBtn } from "../../Components/InputElements";
import axios from "axios";
import Swal from "sweetalert2";
import { ConfigContext } from "../../Context/ConfigContext";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../Components/GlobalFunctions";
import Select from "react-select";

const Project = () => {
  const { projectId } = useParams();
  const { apiURL, apiHeaderJson, apiHeaderFile } = useContext(ConfigContext);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loadingList, setLoadingList] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [reload, setReload] = useState(false);
  const [project_title, setproject_title] = useState("");
  const [project_description, setproject_description] = useState("");
  const [project_address, setproject_address] = useState("");
  const [project_latlng, setproject_latlng] = useState("");
  // const [project_status, setproject_status] = useState("");
  const [project_start_date, setproject_start_date] = useState("");
  const [projectTitleError, setProjectTitleError] = useState("");
  const [projectAddressError, setProjectAddressError] = useState("");
  const [projectPinError, setProjectPinError] = useState("");
  const [projectLatLongError, setProjectLatLongError] = useState("");
  const [projectDescripitionError, setProjectDescripitionError] = useState("");
  const [projectStartDateError, setProjectStartDateError] = useState("");
  const [projectEndDateError, setProjectEndDateError] = useState("");
  const [project_end_date, setproject_end_date] = useState("");
  const [project_id, setproject_id] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [clienttRow, setClientRow] = useState([]);
  const [profile_id, setprofile_id] = useState("");
  const [profileId_Error, setProfileIdError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project_title") {
      setproject_title(value);
      setProjectTitleError("");
    } else if (name === "project_address") {
      setproject_address(value);
      setProjectAddressError("");
    } else if (name === "pinNumber") {
      setPinNumber(value);
      setProjectPinError("");
    } else if (name === "project_latlng") {
      setproject_latlng(value);
      setProjectLatLongError("");
    } else if (name === "project_start_date") {
      setproject_start_date(value);
      setProjectStartDateError("");
    } else if (name === "project_end_date") {
      setproject_end_date(value);
      setProjectEndDateError("");
    } else if (name === "project_description") {
      setproject_description(value);
      setProjectDescripitionError("");
    } else if (name === "Client") {
      setprofile_id(value);
      setProfileIdError("");
    } else if (name === "keyword") {
      setKeyword(value);
    }
  };

  const handleFilter = () => {
    setPage(1);
    setReload(true);
  };
  const validateForm = () => {
    let isValid = true;

    if (!project_title.trim()) {
      setProjectTitleError("Project Name is required");
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s]+$/.test(project_title)) {
      setProjectTitleError(
        "Project Name should not contain special characters"
      );
      isValid = false;
    } else {
      setProjectTitleError("");
    }

    if (!project_address.trim()) {
      setProjectAddressError("Project Location is required");
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s,.-]+$/.test(project_address.trim())) {
      setProjectAddressError("Invalid Project Location format");
      isValid = false;
    } else {
      setProjectAddressError("");
    }

    if (!pinNumber.trim()) {
      setProjectPinError("Project PIN Number is required");
      isValid = false;
    } else if (!/^\d{4,6}$/.test(pinNumber)) {
      setProjectPinError("Project PIN Number must be 4 to 6 digits only");
      isValid = false;
    } else {
      setProjectPinError("");
    }

    if (!project_latlng.trim()) {
      setProjectLatLongError("Project Latitude/Longitude is required");
      isValid = false;
    } else if (!isValidLatLng(project_latlng)) {
      setProjectLatLongError("Project Latitude/Longitude is Invalid");
      isValid = false;
    } else {
      setProjectLatLongError("");
    }

    if (!project_description.trim()) {
      setProjectDescripitionError("Project Description is required");
      isValid = false;
    } else {
      setProjectDescripitionError("");
    }

    if (!project_start_date.trim()) {
      setProjectStartDateError("Project Start Date is required");
      isValid = false;
    } else {
      setProjectStartDateError("");
    }

    if (!project_end_date.trim()) {
      setProjectEndDateError("Project End Date is required");
      isValid = false;
    } else if (
      project_start_date &&
      project_end_date &&
      project_start_date > project_end_date
    ) {
      setProjectEndDateError(
        "Project Start Date must be earlier than Project End Date"
      );
      isValid = false;
    } else {
      setProjectEndDateError("");
    }

    if (!profile_id) {
      setProfileIdError("Client Name is required");
      isValid = false;
    } else {
      setProfileIdError("");
    }

    return isValid;
  };

  const isValidLatLng = (latlng) => {
    const regex =/^\d{1,2}\.\d{4}° [NS], \d{1,3}\.\d{4}° [EW]$/;
    return regex.test(latlng);
  };
  // const isValidLatLng = (latlng) => {
  //   const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
  //   return regex.test(latlng);
  // };

  const GetInfo = () => {
    const headers = apiHeaderJson;
    setLoadingList(true);
    axios
      .get(`${apiURL}api/projects/${projectId}`, {
        headers,
      })
      .then((response) => {
        const { data, success } = response.data;

        setLoadingList(false);
        if (success === true) {
          setproject_title(data.project_title);
          setproject_description(data.project_description);
          setproject_address(data.project_address);
          setproject_latlng(data.project_latlng);
          setproject_start_date(data.project_start_date);
          setproject_end_date(data.project_end_date);
          setPinNumber(String(data.project_pin_code));
          setprofile_id(data.project_client_id);
          setproject_id(data.project_id);
        }
      });
  };

  const GetClientList = async () => {
    try {
      const response = await axios.get(`${apiURL}api/profiles`, {
        params: { profile_type: 3 },
      });
      const { data, success } = response.data;
      if (success) {
        setClientRow(
          data.map((row) => ({
            value: row.profile_id,
            label: row.profile_name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedOption) => {
    setprofile_id(selectedOption ? selectedOption.value : null);
    setProfileIdError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    try {
      const headers = apiHeaderJson;
      let Body = {
        project_client_id: profile_id,
        project_title,
        project_description,
        project_address,
        project_latlng,
        project_pin_code: pinNumber,
        project_status: "0",
        project_start_date,
        project_end_date,
        created_by: localStorage.getItem("user_id"),
        created_date: formatDate(),
      };

      if (projectId) {
        Body.project_id = projectId;
      }

      const requestMethod = projectId ? "put" : "post";
      const url = `${apiURL}api/projects${projectId ? `/${projectId}` : ""}`;
      const response = await axios[requestMethod](url, Body, { headers });
      const { success } = response.data;

      Swal.fire({
        title: `<strong>${success ? "Success" : "Failed"}</strong>`,
        // text: response.data.message,
        text: projectId
          ? "Project Details Updated Successfully"
          : "Project Created Successfully",
        icon: success ? "success" : "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      if (success) {
        resetForm();
        setLoadingList(true);
        // GetList();
        navigate("/ProjectList");
      } else {
        setLoadingList(true);
        // GetList();
      }
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setproject_id(0);
    setproject_title("");
    setproject_description("");
    setproject_latlng("");
    setproject_start_date("");
    setproject_end_date("");
    setProjectPinError("");
    setproject_address("");
    setproject_latlng("");
  };

  const handleClear = () => {
    setKeyword("");
    setReload(true);
  };

  useEffect(() => {}, [page]);
  useEffect(() => {
    GetClientList();
  }, []);
  useEffect(() => {
    if (projectId) {
      GetInfo();
    }
  }, [reload, projectId]);

  return (
    <div className="main-content">
      <div className="page-content">
        {loadingList === false ? (
          <div className="container-fluid">
            <br />
            <PageTitle title={`Projects`} primary={`Home`} />
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div
                    className="card-header align-items-center d-flex"
                    style={{
                      background: "#257180",
                    }}
                  >
                    <h4 className="card-title mb-0 flex-grow-1 text-white">
                      New Project
                    </h4>

                    <div className="avatar-xs  flex-shrink-0">
                      <NavLink
                        to={"/ProjectList"}
                        className="avatar-title bg-white rounded-circle fs-4"
                        style={{
                          color: "#184c8f",
                        }}
                      >
                        <i className="las la-list"></i>
                      </NavLink>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <form onSubmit={handleSubmit} className="row">
                          <div className="col-md-3 mt-3">
                            <label for="project_title">Project Name :</label>
                            <input
                              type="text"
                              id="project_title"
                              name="project_title"
                              value={project_title}
                              onChange={handleInputChange}
                              className={`form-control `}
                              placeholder="Please Enter Project Name"
                            />
                            {projectTitleError && (
                              <div className="text-danger">
                                {projectTitleError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="project_address">
                              Project Location :
                            </label>
                            <input
                              type="text"
                              id="project_address"
                              name="project_address"
                              value={project_address}
                              onChange={handleInputChange}
                              className={`form-control `}
                              placeholder="Please Enter Project Location"
                            />
                            {projectAddressError && (
                              <div className="text-danger">
                                {projectAddressError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="pinNumber">PIN Number :</label>
                            <input
                              type="number"
                              id="pinNumber"
                              name="pinNumber"
                              value={pinNumber}
                              onChange={handleInputChange}
                              className={`form-control `}
                              placeholder="Please Enter PIN Number"
                            />
                            {projectPinError && (
                              <div className="text-danger">
                                {projectPinError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="project_latlng">
                              Project Latitude/Longitude :
                            </label>
                            <input
                              type="text"
                              id="project_latlng"
                              name="project_latlng"
                              value={project_latlng}
                              onChange={handleInputChange}
                              className={`form-control `}
                              placeholder="Please Enter Latitude/Longitude"
                            />
                            {projectLatLongError && (
                              <div className="text-danger">
                                {projectLatLongError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="project_description">
                              Project Description :
                            </label>
                            <textarea
                              id="project_description"
                              name="project_description"
                              value={project_description}
                              onChange={handleInputChange}
                              className={`form-control `}
                              placeholder="Please Enter Project Description"
                            />
                            {projectDescripitionError && (
                              <div className="text-danger">
                                {projectDescripitionError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label> Client Name :</label>
                            <Select
                              onChange={handleSelect}
                              options={clienttRow}
                              name="Client"
                              placeholder="Please Select Client Name"
                              value={
                                profile_id
                                  ? clienttRow.find(
                                      (option) => option.value === profile_id
                                    )
                                  : null
                              }
                            />
                            {profileId_Error && (
                              <div className="text-danger">
                                {profileId_Error}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="project_start_date">
                              Project Start Date :
                            </label>
                            <input
                              type="date"
                              id="project_start_date"
                              name="project_start_date"
                              value={project_start_date}
                              onChange={handleInputChange}
                              className={`form-control `}
                            />
                            {projectStartDateError && (
                              <div className="text-danger">
                                {projectStartDateError}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mt-3">
                            <label for="project_start_date">
                              Project End Date :
                            </label>
                            <input
                              type="date"
                              id="project_end_date"
                              name="project_end_date"
                              value={project_end_date}
                              onChange={handleInputChange}
                              className={`form-control `}
                            />
                            {projectEndDateError && (
                              <div className="text-danger">
                                {projectEndDateError}
                              </div>
                            )}
                          </div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "30px" }}
                          >
                            {projectId ? (
                              <SubmitBtn
                                icon={`ri-save-line`}
                                text={`Update`}
                                type={`primary`}
                              />
                            ) : (
                              <SubmitBtn
                                text={`Save`}
                                type={`primary`}
                                icon={`ri-save-line`}
                              />
                            )}
                            {projectId ? (
                              <NavLink to={"/ProjectList"}>
                                <button
                                  style={{ float: "right" }}
                                  type="button"
                                  className={`btn btn-light right btn-label`}
                                  // onClick={resetForm}
                                >
                                  <i
                                    className={` ri-arrow-right-line label-icon align-middle fs-16 ms-2`}
                                  />
                                  New
                                </button>
                              </NavLink>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ContentLoader />
        )}
      </div>
    </div>
  );
};

export default Project;
